import "@fontsource/josefin-sans/400.css"
import "@fontsource/josefin-sans/500.css"
import "@fontsource/josefin-sans/700.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"
import "@fontsource/noto-sans-tc/400.css"
import "@fontsource/noto-sans-tc/600.css"
import "@fontsource/noto-sans-tc/900.css"

import 'public/js/sweetalert';
import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;

